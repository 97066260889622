<!--
 * @Description: 停车管理--停车订单管理--form页面
 * @Author: 小广
 * @Date: 2019-06-05 13:37:27
 * @LastEditors: 小广
 * @LastEditTime: 2019-11-22 15:11:37
 -->
<template>
  <div class="parkOrderManageForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :footerShow="false"
      @update="update"
    >
      <col2-detail>
        <col2-block title="订单信息">
          <col2-item
            label="订单编号"
            :text="detailItem.carParkApplicationV.serialNumber"
          />
          <col2-item
            label="订单状态"
            :text="applicationStatusMap[detailItem.carParkApplicationV.applicationStatus]"
          />
          <col2-item
            label="服务商家"
            :text="detailItem.busName"
          />
          <col2-item
            label="下单类型"
            :text="sourceTypeMap[detailItem.sourceType]"
          />
          <col2-item
            label="预约时间"
            text="/"
          />
          <col2-item
            label="下单时间"
            :text="detailItem.carParkApplicationV.intime"
          />
          <col2-item
            label="订单类型"
            :text="detailItem.orderTypeStr"
          />
          <col2-item
            label="物流方式"
            text="/"
          />
          <col2-item
            label="物流金额"
            text="¥0.00"
          />
          <col2-item
            label="支付状态"
            :text="formPayStatusMap[detailItem.payStatus]"
          />
          <col2-item
            label="订单金额"
            :text="`¥${detailItem.orderMoneyTotal}`"
          />
          <col2-item
            label="支付方式"
            :text="paymentChannelMap[detailItem.moneyType]"
          />
          <col2-item
            label="抵价票券"
            :text="detailItem.couponInfo"
          />
          <col2-item
            label="支付时间"
            :text="detailItem.payTime ? detailItem.payTime : '/'"
          />
          <col2-item
            label="实付金额"
            :text="`¥${detailItem.orderMoneyReal}`"
          />
        </col2-block>
        <col2-block title="下单人信息">
          <col2-item
            label="下单人姓名"
            :text="detailItem.orderUserInfoV.userName"
          />
          <col2-item
            label="下单人电话"
            :text="detailItem.orderUserInfoV.userPhone"
          />
          <col2-item
            label="下单人地址"
            :text="detailItem.orderUserInfoV.userAddress"
          />
        </col2-block>
        <col2-block title="订单内容">
          <div class="table-wrapper">
            <table-panel
              :headers="orderHeaders"
              :tableData="orderData"
              :hasOperateColumn="false">
            </table-panel>
          </div>
          <col2-item
            label="订单描述"
            :text="detailItem.remark"
          />
        </col2-block>
        <col2-block title="发票信息">
          <template #headerOperate>
            <col2-item v-if="showInvoiceHandle()" style="margin-left: 10px" >
                <v-button type="text" text="查看发票" @click="lookInvoice"></v-button>
            </col2-item>
          </template>
          <div v-if="invoiceInfo && invoiceInfo.invoiceType === 1">
            <col2-item label="抬头类型" text="个人"/>
            <col2-item label="发票抬头" :text="invoiceInfo.invoiceTitle"/>
            <!-- 增值税电子普通发票/增值税专用发票??? -->
            <col2-item label="发票种类" :text="invoiceInfo.invoiceTypeText"/>
            <col2-item label="收票人姓名" :text="invoiceInfo.userName"/>
            <col2-item label="收票人手机" :text="invoiceInfo.userPhone"/>
            <col2-item label="收票人邮箱" :text="invoiceInfo.userEmail"/>
            <col2-item label="收票人地址" :text="invoiceInfo.userAddress"/>
            <col2-item label="发票状态" :text="invoiceStatusMap[invoiceInfo.status] || ''"/>
            <col2-item v-if="invoiceInfo.invoiceNumber && invoiceInfo.invoiceNumber.length" label="发票号码" :text="invoiceInfo.invoiceNumber"/>
          </div>
          <div v-else-if="invoiceInfo && (invoiceInfo.invoiceType === 2 || invoiceInfo.invoiceType === 3)">
            <col2-item label="抬头类型" text="企业"/>
            <col2-item label="发票抬头" :text="invoiceInfo.invoiceTitle"/>
            <col2-item label="发票种类" :text="invoiceInfo.invoiceTypeText"/>
            <col2-item label="纳税人识别号" :text="invoiceInfo.taxpayerNumber"/>
            <col2-item label="收票人姓名" :text="invoiceInfo.userName"/>
            <col2-item label="企业注册地址" :text="invoiceInfo.registeredAddress"/>
            <col2-item label="收票人手机" :text="invoiceInfo.userPhone"/>
            <col2-item label="企业注册电话" :text="invoiceInfo.registeredPhone"/>
            <col2-item label="收票人邮箱" :text="invoiceInfo.userEmail"/>
            <col2-item label="开票银行" :text="invoiceInfo.bank"/>
             <col2-item label="收票人地址" :text="invoiceInfo.userAddress"/>
            <col2-item label="银行账号" :text="invoiceInfo.bankAccount"/>
            <col2-item label="发票状态" :text="invoiceStatusMap[invoiceInfo.status] || ''"/>
            <col2-item v-if="invoiceInfo.invoiceNumber && invoiceInfo.invoiceNumber.length" label="发票号码" :text="invoiceInfo.invoiceNumber"/>
          </div>
          <div v-else>
            <col2-item label="发票状态" text="不开票"/>
          </div>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { getOrderDetailURL } from './api'
import { Col2Block, Col2Item, Col2Detail, TablePanel } from 'components/bussiness'
import {
  payCategoryMap,
  suitTypeMap,
  rentTypeMap,
  sourceTypeMap,
  applicationStatusMap,
  formPayStatusMap,
  invoiceStatusMap,
  invoiceTypeMap
} from './map'
import { paymentChannelMap } from 'common/map'

export default {
  name: '',
  components: {
    Col2Block,
    Col2Item,
    Col2Detail,
    TablePanel
  },
  data () {
    return {
      submitConfig: {},
      detailItem: {
        carParkApplicationV: {},
        orderUserInfoV: {},
        invoiceInfoV: {}
      },
      // 发票类型
      invoiceType: 0,
      suitTypeMap: suitTypeMap,
      rentTypeMap: rentTypeMap,
      sourceTypeMap: sourceTypeMap,
      applicationStatusMap: applicationStatusMap,
      formPayStatusMap: formPayStatusMap,
      invoiceStatusMap: invoiceStatusMap,
      invoiceTypeMap: invoiceTypeMap,
      paymentChannelMap: paymentChannelMap,
      orderHeaders: [
        {
          prop: 'rentType',
          label: '租赁方式'
        },
        {
          prop: 'carSlotName',
          label: '车位/区域'
        },
        {
          prop: 'suitType',
          label: '套餐类型'
        },
        {
          prop: 'purchasePrice',
          label: '价格'
        },
        {
          prop: 'purchaseCount',
          label: '购买数量'
        },
        {
          prop: 'applicationTimeTag',
          label: '办理/到期时间',
          minWidth: '150'
        }
      ],
      orderData: [],
      invoiceInfo: undefined
    }
  },

  created () {
    const { id } = this.$route.query
    this.submitConfig = {
      queryUrl: `${getOrderDetailURL}/${id}/order`
    }
  },

  mounted () {
    this.$refs.formPanel.getData()
    this.$setBreadcrumb('订单详情')
  },

  methods: {

    update (data) {
      let detailItem = Object.assign({}, data)

      // 优惠券
      let couponInfo = `使用${detailItem.discountNum}张，总共抵价¥${detailItem.orderMoneyDiscount}`
      detailItem.couponInfo = couponInfo

      let orderInfo = detailItem.carParkApplicationV
      if (orderInfo) {
        // 订单类型
        let orgName = orderInfo.orgName ? `（${orderInfo.orgName}）` : ''
        detailItem.orderTypeStr = `${payCategoryMap[orderInfo.payCategory]}${orgName}`

        // 订单信息的处理
        let order = {
          rentType: rentTypeMap[orderInfo.rentType],
          carSlotName: orderInfo.carSlotName,
          suitType: suitTypeMap[orderInfo.suitType],
          purchasePrice: `${orderInfo.purchasePrice}元`,
          purchaseCount: `${orderInfo.purchaseCount}个`,
          applicationTimeTag: orderInfo.applicationTimeTag
        }
        this.orderData = [order]
      }

      // 发票信息
      let invoiceInfo = detailItem.invoiceInfoV
      if (invoiceInfo) {
        let invoiceTypeText = ''
        if (invoiceInfo.invoiceType === 3) {
          // 3 企业专票
          invoiceTypeText = '增值税专用发票'
        } else {
          // 1个人普票 2企业普票
          invoiceTypeText = invoiceInfo.invoiceMaterial === 1 ? '增值税电子普通发票' : '增值税纸质普通发票'
        }
        invoiceInfo.invoiceTypeText = invoiceTypeText
      }
      this.invoiceInfo = invoiceInfo
      this.detailItem = detailItem
    },

    showInvoiceHandle () {
      let show = false
      let invoiceInfo = this.invoiceInfo
      if (invoiceInfo) {
        // 电子发票
        if (invoiceInfo.invoiceMaterial === 1) {
          let statusShow = invoiceInfo.status === 1 || invoiceInfo.status === 3
          let invoicePdfUrlShow = (invoiceInfo.invoicePdfUrl && invoiceInfo.invoicePdfUrl.length > 0)
          // 电子发票，开票成功/作废状态，有发票地址，才显示
          if (statusShow && invoicePdfUrlShow) {
            show = true
          }
        }
      }
      return show
    },

    lookInvoice () {
      window.open(this.invoiceInfo.invoicePdfUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.parkOrderManageForm-wrapper {
  .table-wrapper {
    width: 100%;
    margin-left: 85px;
  }

  .invoice-status-wrapper {
    display: flex;

    .invoice-text {
      margin-right: 20px;
    }
  }
}
</style>
